<template>
  <div class="work-container" id="work">
    <page-banner
      v-bind:title="banner.title"
      v-bind:introduction="banner.introduction"
      v-bind:arrowGoto="banner.arrowGoto"
      v-bind:bg="'/images/background/header-works.jpg'"
    />
    <div id="works" class="works">
      <section
        v-for="(item, i) in works"
        class="work"
        v-bind:key="i"
        v-bind:id="item.Id"
      >
        <h2>
          {{ item.Client }}
        </h2>
        <p>
          <b>{{ item.Sale }}</b>
        </p>
        <p class="description">
          {{ item.Description }}
        </p>
        <div class="gallery" v-if=" windowWidth > 680">
          <div class="column-one" v-bind:class="{'only-one' : item.CarouselList.length === 1}">
            <div class="asset-container" data-aos-disable="mobile" data-aos="fade-up" data-aos-duration="1000" v-for="(asset, i) in evenArray(item.CarouselList)" v-bind:key="i">
              <div
                v-if="asset.type === 'video'"
                title="video"
                class="embed-container">
              <iframe
                v-bind:title="item.Client"
                :src=" 'https://player.vimeo.com/video/' + asset.url"
                frameborder="0"
                allow="autoplay; fullscreen"
                allowfullscreen></iframe>
              </div>
              <img
                v-if="asset.type === 'image'"
                v-bind:src="url + asset.url"
                v-bind:alt="item.Client" />
            </div>
          </div>
          <div class="column-two" v-bind:class="{'only-one' : item.CarouselList.length === 1}">
            <div class="asset-container" data-aos-disable="mobile" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="400" v-for="(asset, i) in oddArray(item.CarouselList)" v-bind:key="i">
              <div
                v-if="asset.type === 'video'"
                title="video"
                class="embed-container">
              <iframe
                v-bind:title="item.Client"
                :src=" 'https://player.vimeo.com/video/' + asset.url"
                frameborder="0"
                allow="autoplay; fullscreen"
                allowfullscreen></iframe>
              </div>
              <img
                v-if="asset.type === 'image'"
                v-bind:src="url + asset.url"
                v-bind:alt="item.Client" />
            </div>
          </div>
        </div>
        <div class="gallery" v-else>
          <div class="column-one only-one">
            <div class="asset-container" data-aos-disable="mobile" data-aos="fade-up" data-aos-duration="1000" v-for="(asset, i) in item.CarouselList" v-bind:key="i">
              <div
                v-if="asset.type === 'video'"
                title="video"
                class="embed-container">
              <iframe
                v-bind:title="item.Client"
                :src=" 'https://player.vimeo.com/video/' + asset.url"
                frameborder="0"
                allow="autoplay; fullscreen"
                allowfullscreen></iframe>
              </div>
              <img
                v-if="asset.type === 'image'"
                v-bind:src="url + asset.url"
                v-bind:alt="item.Client" />
            </div>
          </div>
        </div>
        <hr>
      </section>
    </div>
  </div>
</template>

<script>
import PageBanner from "../components/PageBanner.vue";

export default {
  name: "Home",
  components: {
    PageBanner,
  },
  data() {
    return {
      banner: {
        title: "Selected<br>Works",
        introduction: "",
        arrowGoto: "#works",
      },
      url: "https://strapi.uptowncreative.io",
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    };
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
    },
    oddArray(list){
      let newList = [];
      for(let i = 0; i<list.length; i++){
        if (i % 2 === 1){
          newList.push(list[i]);
        }
      }
      return newList;
    },
    evenArray(list){
      let newList = [];
      for(let i = 0; i<list.length; i++){
        if (i % 2 === 0){
          newList.push(list[i]);
        }
      }
      return newList;
    }
  },
  computed: {
    works() {
      return this.$store.getters.works;
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style scoped lang="scss">
.work {
  display: flex;
  flex-direction: column;
  align-items: center;
  &:nth-last-child(1){
    border-bottom: none;
  }
  h2 {
    margin: 170px auto 0;
    text-align: center;
    max-width: 500px;
    line-height: 68px;
  }
  p {
    font-family: $secondaryFont;
    margin: 5px auto 35px;
    max-width: 700px;
    text-align: center;
    font-size: 21px;
    line-height: 34px;
  }
  .description{
    font-weight: 300;
    margin: 30px auto;
  }
  .gallery{
    display: flex;
    margin-top: 20px;
    width: 100%;
    margin-bottom: 100px;
    .column-one, .column-two{
      width: 50%
    }
    .column-one{
      margin-right: 10px;
      &.only-one{
        margin: 0 auto;
        width: 100%;
      }
    }
    .column-two{
      margin-left: 10px;
      &.only-one{
        display: none;
      }
    }
    .asset-container{
      width: 100%;
      margin-top: 10px;
      img{
        width: 100%;
      }
      .embed-container {
        position: relative;
        padding-bottom: 56.25%;
        overflow: hidden;
      }
      .embed-container iframe,
      .embed-container object,
      .embed-container embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  hr {
    width: 80%;
    height: 1px;
    border: none;
    border-top: 1px solid #000;
  }
}

@media screen and (max-width: $mobile) {
  .work {
    h2{
      margin-top: 60px;
      font-size: 50px;
      line-height: 60px;
      margin-bottom: 20px;
    }
    p{
      margin: 0 20px;
    }
    .description{
      margin: 20px;
    }
  }
}
</style>
