<template>
  <div class="term">
      <h1>
          Services Agreement
      </h1>
      <p>
          By engaging the services of Uptown Creative, Inc. operating as (o/a) Austin Robbins Agency (“Austin Robbins Agency”) to complete work for the benefit of you, the Customer (the “Work”), you represent that you are able to bind the Customer at law and hereby agree that the Customer shall be bound by the agreement between the parties as evidenced by the terms set out herein and as clarified in the communications between Austin Robbins Agency and the Customer concerning the agreed upon services to be provided by Austin Robbins Agency (the “Agreement”). <br><br>
          1) The Work: Completion and Cost, Changes, and Delays: (1) Completion and Cost of the Work: The completion date and the cost of the Work shall be included in the Agreement between Austin Robbins Agency and the Customer. If such terms are not clarified in said Agreement, then Austin Robbins Agency reserves the right to assume a reasonable completion date and a reasonable cost for the completion of the Work from the timelines, completion dates, and costs of previous work which Austin Robbins Agency completed for the Customer and/or median market rates in Canada at the time the Customer enters the Agreement. (2) Changes to the Work: Any changes to the structure, format, flow chart, pictures, images, videos, text, and any and all content provided by the Customer to Austin Robbins Agency for the Work, and any other changes which require unexpected modifications which the Customer authorizes and are required for the completion of the Work shall result in additional charges to any estimated cost for the Work. Any changes may also result in a delay in the completion date of the Work. (3) Delays in the Completion of the Work: Austin Robbins Agency shall not be liable nor responsible for any and all liabilities and damages which flow from any delays in the completion of the Work. Austin Robbins Agency requires the Customer to sign-off on the Work before any or all of parts of the Work shall go live or shall be provided in final form to the Customer. <br><br>
          2) Fees: (1) Fees and Monthly Billing: Monthly interim billings from Austin Robbins Agency for completion of the Work to the date of issuance shall include Austin Robbins Agency's fees for its services rendered within the requisite billing cycle, any applicable taxes, and any and all disbursements which Austin Robbins Agency incurs on behalf of the Customer which shall include but will not be limited to third party hosting vendor(s) and third party maintenance vendor(s) (the “Disbursements”). (2) Due Date for Payment of Fees: Austin Robbins Agency's interim accounts are due fifteen (15) days past the date of issuance. <br><br>
          3) Refunds, Suspensions, and Cancellations: (1) Refunds: Austin Robbins Agency shall not issue any refunds for services rendered when completing the Work and will not refund for any costs associated with any and all disbursements and applicable taxes. (2) Suspensions and Cancellations: Austin Robbins Agency reserves the right to suspend or cancel its services on the Work if its interim accounts are unpaid in full forty-five (45) days past the date of issuance of the interim account without notice to the Customer (the “Lapse”). Austin Robbins Agency also reserves the right to suspend or permanently deactivate the contracts it has with any third party hosting vendor(s), third party maintenance vendor(s), and/or any other third parties which it entered into in order to complete the Work at the time the suspension or cancellation comes into force and effect whether by notice or by the Lapse. (3) Billing and Payment on Cancellation: If Austin Robbins Agency cancels its Agreement with the Customer, it shall issue its final account in a timely manner for any and all services rendered in completing the Work, including Austin Robbins Agency's fees, all Disbursements and disbursements, and any applicable taxes. Any and all contracts which Austin Robbins Agency entered into with any third parties for the completion of the Work for the benefit of the Customer shall be cancelled and the third party hosting vendor(s) and third party maintenance vendor(s) contracts and services shall be permanently deactivated. (4) Unpaid Accounts: All unpaid and outstanding accounts, invoices, or portions thereof shall accrue interest at a rate of prime plus one percent (Prime + 1%) as determined by the Bank of Canada. <br><br>
          4) Non-Exclusivity: All templates which are used by Austin Robbins Agency to complete the Work are not exclusive to the Customer, unless otherwise agreed by the parties hereto. Austin Robbins Agency reserves the right to reuse, resell, or otherwise use or redistribute any and all of the templates used in completing the Work for the benefit of the Customer. The Customer shall not retain any intellectual property ownership rights to the templates used to complete the Work. For clarity, the templates include but are not limited to the templates, unique text fonts and colours, unique hyperlink colours and header background videos, the text for the headings, the body of the text, the footers, and the contact information text, but shall not include any content which the Customer provides to Austin Robbins Agency to complete the Work. <br><br>
          5) Limitation of Liability - Content: Austin Robbins Agency IS NOT LIABLE OR RESPONSIBLE FOR ANY AND ALL CONTENT WHICH THE CUSTOMER PROVIDES TO Austin Robbins Agency TO COMPLETE THE WORK. This shall include but shall not be limited to: (1) Intellectual Property Rights of Third Parties: Austin Robbins Agency is not liable and accepts no responsibility for ensuring that any images, video or photographic content, logos, trademarks, tag-lines, wording or descriptions or any other content provided by the Customer to Austin Robbins Agency does not infringe any copyright, trademark, and any and all intellectual property rights of any third party to the Agreement. The Customer is responsible for ensuring such content does not breach any and all intellectual property rights of third parties and assumes all liability for any and all breaches to said third party intellectual property rights for any and all damages which flow from such a breach. (2) Illegal, Obscene, or Inappropriate: Austin Robbins Agency is not liable and accepts no responsibility for ensuring that any content provided by the Customer to Austin Robbins Agency is not illegal, obscene, or otherwise inappropriate to the reasonable person in any and all jurisdictions where the Customer operates. The Customer assumes any and all liabilities and responsibilities for ensuring such content does not breach any laws in any jurisdictions in which it operates. <br><br>
          6) Limitation of Liability - Function of Work: Austin Robbins Agency does not guarantee nor warranty uninterrupted or error-free service of the Work or any part of the Work. Austin Robbins Agency does not guarantee Work to be ADA (Americans with Disabilities Act) standards compliant. Austin Robbins Agency does not guarantee Work to be compliant with any rules or regulations set forth by any local and/or national MLS/real estate/Realtor board and/or governing body. Austin Robbins Agency shall not be held liable nor responsible for any and all damages and liabilities which flow from any interruptions and/or errors of the Work, which may include, but are not limited to service interruptions, errors, omissions, bugs, downtime, failure to comply with standards or data loss. The Customer assumes the responsibility for liabilities and damages which flow from any and all such interruptions and errors. <br><br>
          7) Limitation of Liability - Branding / Copywriting / Naming Services: Austin Robbins Agency is not liable and accepts no responsibility for ensuring that any images, video or photographic content, logos, trademarks, tag-lines, wording or descriptions or any other content does not infringe any copyright, trademark, and any and all intellectual property rights of any third party to the Agreement. The Customer is responsible for ensuring such content does not breach any and all intellectual property rights of third parties and assumes all liability for any and all breaches to said third party intellectual property rights for any and all damages which flow from such a breach. <br><br>
          8) Third Party Hosting and Maintenance Vendor(s): (1) Third Party Hosting Vendor(s): Austin Robbins Agency shall hire third party hosting vendor(s) to complete the Work which it will select at its sole discretion unless otherwise specified in the Agreement. Austin Robbins Agency shall not be liable nor held responsible for any and all damages which directly or indirectly result from any and all interruptions by the third party hosting vendor(s) in servicing the Customer, which include but are not limited to outages, downtime, slow downs, upgrades, end-of-life or end-of-sale, and all other service interruptions. (2) Third Party Maintenance Vendor(s): Austin Robbins Agency shall hire third party maintenance vendor(s) to provide a service called the Content Management Solution (or “CMS”) which Austin Robbins Agency will select at its sole discretion unless otherwise specified in the Agreement. Austin Robbins Agency shall not be liable nor held responsible for any and all damages which directly or indirectly result from any and all interruptions by the third party maintenance vendor(s) in servicing the Customer, which include but are not limited to outages, downtime, slow downs, upgrades, end-of-life or end-of-sale, and all other service interruptions. (3) Hosting the Customer's Email: Austin Robbins Agency shall not host the Customer's email. <br><br>
          9) Search Engines: Austin Robbins Agency does not guarantee nor accept any liability or responsibility flowing from the Customer's website attaining a specific placement or ranking, any Search Engine Optimization, and/or acceptance in or by a specific search engine. <br><br>
          10) Choice of Law - Agreement to Choice of Law: This Agreement shall be construed and enforced in accordance with, and the rights of Austin Robbins Agency and the Customer shall be governed by, the laws of the Province of Ontario and the laws of Canada applicable thereto excluding any rule or principle of conflicts of laws that may provide otherwise. <br><br>
          11) Dispute Resolution: If any dispute arises among Austin Robbins Agency and the Customer with respect to any matter which cannot be resolved by the terms of this Agreement or by agreement between Austin Robbins Agency and the Customer, the matter in dispute shall be resolved by binding arbitration. Either party to the dispute may at any time require arbitration by giving written notice thereof to the other party hereto setting out in reasonable detail the issue in dispute. The dispute shall be determined by a single arbitrator mutually appointed by the parties provided the parties can agree upon one (1) within ten (10) days from the receipt of the notice. Failing such mutual appointment, an arbitrator shall be appointed by a court of competent jurisdiction upon the application of any of the parties. The arbitration shall proceed in accordance with the provisions of the Arbitration Act, 1991, S.O. 1991, c. 17, as amended (Ontario). The decision arrived at by the arbitrator, whether mutually agreed to or appointed by a court of competent jurisdiction, shall be final and binding and no appeal shall lie therefrom. <br><br>
          12) Enurement: The Agreement shall be binding upon and enure to the benefit of the parties hereto and their respective heirs, executors, administrators, successors, and assigns.
      </p>
  </div>
</template>

<script>
export default {
  name: "Term",
};
</script>

<style scoped lang="scss">
.term{
  text-align: center;
  margin: 40px;
}
</style>
