<template>
  <div class="page-banner" v-bind:style="styleObject" >
    <h1 v-if="title">
      <span v-html="title"></span>
    </h1>
    <p v-if="introduction">
      {{ introduction }}
    </p>
    <!-- <router-link
      v-if="arrowGoto"
      v-bind:to="arrowGoto"
      class="ca3-scroll-down-link"
    >
      <img src="/images/arrow-down.png" alt="Animted Down arrow" class="ca3-scroll-down-arrow">
    </router-link> -->
  </div>
</template>

<script>
export default {
  name: "PageBanner",
  props: {
    bg: String,
    title: String,
    introduction: String,
    arrowGoto: String,
  },
  data(){
    return {
      styleObject: {
        background: this.bg ?  `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("${this.bg}")` : 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("/images/page-banner.png")',
        backgroundSize: 'cover',
      }
    }
  }
};
</script>

<style scoped lang="scss">
.page-banner {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("/images/page-banner.png");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  color: #fff;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  height: calc(100vh - 84px);
  max-height: 625px;
  h1 {
    text-transform: uppercase;
    max-width: 600px;
    margin: 0 auto;
  }
  p {
    font-family: $secondaryFont;
    margin: 0 auto;
    max-width: 675px;
    letter-spacing: -0.04em;
    font-size: 21px;
    line-height: 34px;
  }
}

@media screen and (max-width: $mobile) {
  .page-banner {
    p {
      margin-top: 20px;
      max-width: 300px;
    }
  }
}
</style>
