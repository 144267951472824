<template>
  <div class="term">
      <h1>
        Privacy Policy
      </h1>
      <p><b>Effective Date: July 2, 2024</b></p>
      <p>
        <b>1. Introduction</b><br><br>
        Uptown Creative, Inc. operating as (o/a) Austin Robbins Agency (“we,” “us,” or “our”) respects your privacy and is committed to protecting your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website [austinrobbins.com] and engage with our services.<br><br>
        <b>2. Information We Collect</b><br><br>
        <b>Personal Information: </b>We may collect the following types of personal information when you contact us via our website form:<br><br>
        •	Name (optional)<br>
        •	Email address (required)<br>
        •	Phone number (optional)<br>
        •	Message content (optional)<br><br>
        <b>Sensitive Information:</b>We use Xero for invoicing, integrated with Stripe for credit card payments. Clients input their credit card information directly into the Xero-powered payment form, which we do not store.<br><br>
        <b>3. How We Collect Information</b><br><br>
        We collect information directly from you when you provide it through:<br><br>
        •	Online forms on our website<br>
        •	Email communications<br><br>
        <b>4. Use of Your Information</b><br><br>
        We use your information to:<br><br>
        •	Provide customer service<br>
        •	Complete work for our clients<br>
        •	Communicate with you regarding your inquiries<br><br>
        <b>5. Sharing Your Information</b><br><br>
        We may share your information with our core team to fulfill specific project requests. We take reasonable measures to ensure that your information is used only for the purposes for which it was shared.<br><br>
        <b>6. Data Storage and Security</b><br><br>
        Your data is stored using secure storage solutions, both online and offline. We implement various security measures, including two-factor authentication (2FA) where possible, to protect your data.<br><br>
        <b>7. Data Retention</b><br><br>
        We retain your personal data for as long as necessary to fulfill the purposes for which it was collected and to comply with applicable laws. We review the necessity of this retention periodically and ensure compliance with relevant regulations. Data is stored within our email systems or archived securely.<br><br>
        <b>8. User Rights</b><br><br>
        You have the right to:<br><br>
        •	Access your personal data<br>
        •	Correct any inaccuracies in your data<br>
        •	Request deletion of your data, except where we are required to retain it for legal, regulatory, or legitimate business purposes<br><br>
        To exercise these rights, please contact us at agency[at]austinrobbins[dot]com.<br><br>
        <b>9. Cookies and Tracking</b><br><br>
        We do not use cookies or other tracking technologies on our website.<br><br>
        <b>10. Third-Party Services</b><br><br>
        We use third-party services to assist with various business operations, such as invoicing, payment processing, project management, and team communication. These services may collect, process, or store your personal data as necessary to provide their functions.<br><br>
        <b>11. Children's Privacy</b><br><br>
        Our website and services are not intended for children under 13, and we do not knowingly collect information from children under 13.<br><br>
        <b>12. International Data Transfers</b><br><br>
        We transfer data between Canada and the USA as part of our business operations. We ensure these transfers comply with applicable laws, including data protection regulations.<br><br>
        <b>13. Contact Information</b><br><br>
        If you have any questions or concerns about our privacy practices, please contact us at:<br><br>
        <b>•	Email:</b> agency[at]austinrobbins[dot]com<br><br>
        <b>14. Policy Updates</b><br><br>
        We may update this Privacy Policy occasionally. Any changes will be posted on our Privacy Policy page.
      </p>
  </div>
</template>

<script>
export default {
  name: "Privacy",
};
</script>

<style scoped lang="scss">
.term{
  text-align: center;
  margin: 40px;
}
</style>
