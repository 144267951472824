<template>
  <div class="team" id="team">
    <page-banner
      v-bind:title="banner.title"
      v-bind:introduction="banner.introduction"
      v-bind:bg="'/images/background/header-team.jpg'"
    />
    <div class="team-container">
      <h1>Team</h1>
      <hr />
      <div class="team-members">
        <div class="member" v-for="(item, i) in members" v-bind:key="i"
          data-aos="fade-up" data-aos-duration="1000" 
        >
          <h2 class="name">
            {{ item.name }}
          </h2>
          <p class="title">
            <b>{{ item.title }}</b>
          </p>
          <p class="bio">
            {{ item.bio }}
          </p>
        </div>
      </div>
    </div>
    <div class="services container-padding">
      <h1>Agency Services</h1>
      <hr />
      <div class="service-container">
        <div v-for="(item, i) in services" class="service" v-bind:key="i" data-aos="fade-up" data-aos-duration="1000"  v-bind:data-aos-delay="`${((i) % 3) * 400}`">
          <h2>
            {{ item.title }}
          </h2>
          <div class="item-list">
            <p v-for="(service, i) in item.services" v-bind:key="i">
              {{ service }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageBanner from "../components/PageBanner.vue";

export default {
  name: "Home",
  components: {
    PageBanner,
  },
  data() {
    return {
      banner: {
        title: "Since 2009",
        introduction:
          "For more than a decade, Austin Robbins Agency has been shaping the landscape of luxury real estate advertising. Our journey is fueled by a passion for creating lasting brands and bespoke strategies tailored to each client's unique story. From the bustling streets of Los Angeles to the iconic skyline of New York.",
      },
      members: [
        {
          name: "Austin",
          title: "Founder & Lead Strategist",
          bio: "Austin leverages a blend of technical expertise and agile leadership skills to deliver value-added services to a discerning client base across North America.",
        },
        {
          name: "Thanh",
          title: "Creative Director",
          bio: "Thanh's work is marked by the synthesis of both intelligence and creativity in a distinctive design style. His ability to create great experiences with complex concepts is what sets his work apart.",
        },
        {
          name: "Jerry",
          title: "Lead Developer",
          bio: "Always searching for groundbreaking technologies, Jerry enjoys building applications that use the latest libraries to produce streamlined interfaces.",
        },
        {
          name: "Cynthia",
          title: "Senior Brand Developer",
          bio: "Cythia specializes in branding and identity. She helps our clients maneuver the complexities of creating a luxury brand while blending the creative nuances of building a unique identity.",
        },
        {
          name: "Olga",
          title: "Senior Designer",
          bio: "Turning the most challenging concepts into beautiful experiences, Olga’s work is marked by a distinctive design style that’s both contemporary and approachable.",
        },
        {
          name: "Shenelle",
          title: "Registered Graphic Designer",
          bio: "Shenelle is a skilled RGD (Registered Graphic Designer) with a proven ability to craft creative, stylish and engaging logos, ad campaigns and print collateral pieces.",
        },
        {
          name: "Shiva",
          title: "Senior Developer",
          bio: "Shiva is an excellent troubleshooter and problem-solver, capable of diagnosing and repairing some of the most complex programming issues.",
        },
        {
          name: "Eleanor",
          title: "Website Administrator",
          bio: "As our dedicated website administrator, Eleanor quickly executes client requests with a remarkable degree of quality and consistency.",
        },
      ],
      services: [
        {
          title: "Identity",
          services: ["Brand Strategy", "Logo Creation", "Style Guides"],
        },
        {
          title: "Print",
          services: [
            "Property Brochures",
            "Direct Mail Campaigns",
            "Prestige Advertisements",
          ],
        },
        {
          title: "Motion",
          services: [
            "Client Films",
            "Social Videos",
            "Interactive eBooks",
          ],
        },
        {
          title: "Digital",
          services: [
            "Tailored Websites",
            "Showcase Sites",
            "Digital Campaigns",
          ],
        },
        {
          title: "Strategies",
          services: [
            "Marketing Decks",
            "Proposal Narratives",
            "Pitch Customization"
          ],
        },
        {
          title: "Partnership",
          services: [
            "Collaborative Support",
            "Media Templates",
            "Site Management",
          ],
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.team-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 0;
  h1{
    text-transform: uppercase;
    letter-spacing: -0.03em;
  }
  hr {
    width: 80%;
  }
  .team-members {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 40px 0;
    .member {
      width: 50%;
      text-align: center;
      margin: 40px 0;
      .name {
        margin-bottom: 0;
      }
      .title {
        font-family: $secondaryFont;
        margin-top: 10px;
      }
      .bio {
        font-family: $secondaryFont;
        font-weight: 300;
        margin: 0 auto;
        max-width: 550px;
      }
    }
  }
}
.services {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("/images/background/bg-services.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  color: #fff;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 80px 0;
  h1 {
    text-transform: uppercase;
    letter-spacing: -0.03em;
    margin: 60px 0 20px;
  }
  hr {
    height: 1px;
    width: 80%;
    border: none;
    border-top: 1px solid #fff;
  }
  .service-container {
    display: flex;
    flex-wrap: wrap;
    .service {
      width: 33.33%;
      display: flex;
      flex-direction: column;
      margin: 40px 0;
      h2 {
        margin-bottom: 10px;
      }
      p {
        font-family: $secondaryFont;
        margin: 5px 0;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .team-container {
    flex-wrap: wrap;
    .team-members {
      margin-top: 0;
      .member {
        margin: 20px 0;
        .bio {
          max-width: 300px;
        }
      }
    }
  }
  .services {
    .service-container {
      .service {
        width: 50%;
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  .team-container {
    flex-wrap: wrap;
    .team-members {
      margin-top: 0;
      .member {
        width: 100%;
        margin: 20px 0;
        .bio {
          max-width: 300px;
        }
      }
    }
  }
  .services {
    background-attachment: scroll;
    .service-container {
      flex-wrap: wrap;
      .service {
        width: 100%;
        margin: 30px auto;
      }
    }
  }
}
</style>
